import * as React from 'react';
import Typography from '@mui/material/Typography';

const Footer: React.FC = () => {
  const footerStyle: React.CSSProperties = {
    position: 'relative',
    height: '210px', // Adjust the height as needed
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white', // Set the background color to white
    paddingTop: '18px' // Add padding at the top for extra space
  };

  const imageStyle: React.CSSProperties = {
    width: 'auto', // Maintain image proportions
    height: '200px', // Set the height of the image to 100 pixels
  };

  const textStyle: React.CSSProperties = {
    fontSize: '16px',
    color: '#9ccd4f',
    textAlign: 'center',
    marginBottom: '10px', // Space between text and image
  };

  return (
    <footer style={footerStyle}>
      <Typography variant="body2" style={textStyle}>
        © 2024 Bürgerenergie Altusried eG. All rights reserved.
      </Typography>
      <img src={process.env.PUBLIC_URL + "/bundesministerium_logo.png"} alt="Footer" style={imageStyle} />
    </footer>
  );
};


export default Footer;
