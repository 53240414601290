// Genossenschaft.tsx

import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import TimeTable from '../components/TimeTable';



interface BoardMember {
  name: string;
  role: string;
  image?: string; 
}

interface BoardProps {
  chairman: BoardMember;
  viceChairman: BoardMember;
}

interface SupervisoryBoardMember {
  name: string;
  role: string;
  image?: string; 
}

interface SupervisoryBoardProps {
  chairman: SupervisoryBoardMember;
  viceChairman: SupervisoryBoardMember;
  members: SupervisoryBoardMember[];
}

const Genossenschaft: React.FC = () => {
  // Data for the components
  const boardProps: BoardProps = {
    chairman: {
      name: 'Hugo Wirthensohn',
      role: 'Vorsitzender',
    },
    viceChairman: {
      name: 'Heribert Hartmann',
      role: 'Stellvertreter',
    },
  };

  const supervisoryBoardProps: SupervisoryBoardProps = {
    chairman: {
      name: 'Bürgermeister Max Boneberger',
      role: 'Vorsitzender',
    },
    viceChairman: {
      name: 'Antje Kasparek',
      role: 'Stellvertreterin',
    },
    members: [
      {
        name: 'Dipl. Ing. (FH) Kurt Zügner',
        role: 'Berater',
      },
      // Add more members as needed
    ],
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div style={{maxWidth: '900px',  fontSize: '18px', marginLeft: 'auto', marginRight: 'auto', padding: '18px', fontFamily: 'Axel, ITC Officina Display, sans-serif',  paddingBottom: '60px'  }}>
      <p style={{ marginBottom: '18px', fontWeight: 'bold' }}>
        Die Bürgerenergie Altusried e.G.
      </p>
      
      <p>
        Die Bürgerenergie Altusried e.G. ist eine Genossenschaft, die im Jahr 2022 gegründet wurde,
        um die Gebäude im Ortszentrum von Altusried mit umweltfreundlicher Nahwärme zu versorgen.
      </p>

      <p>
        Unsere Genossenschaft besteht aus engagierten Menschen, die sich gemeinsam für eine
        nachhaltige Energieversorgung einsetzen. Wir möchten unseren Beitrag dazu leisten, keine
        fossilen Energien mehr zu verbrauchen und gleichzeitig unseren Mitgliedern eine zuverlässige
        und kostengünstige Wärmeversorgung zu bieten.
      </p>

      <div style={{ width: '100%', maxWidth: '900px',  margin: '18px auto', textAlign: 'center' }}>
        <img
          src={`${process.env.PUBLIC_URL}/gruppenbild.jpg`}
          alt="Gruppenbild Vorstandschaft"
          style={{
            width: '100%',
            height: 'auto',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', // Add shadow for a lifted effect
          }}
        />
      </div>

      <p style={{ marginBottom: '18px', fontWeight: 'bold' }}>
        Vorstandschaft
      </p>
      <ul style={{maxWidth: '900px',  fontSize: '18px', marginLeft: 'auto', marginRight: 'auto', fontFamily: 'Axel, ITC Officina Display, sans-serif'  }}>
        <li>{boardProps.chairman.name} ({boardProps.chairman.role})</li>
        <li>{boardProps.viceChairman.name} ({boardProps.viceChairman.role})</li>
      </ul>

      <br />

      <p style={{ marginBottom: '18px', fontWeight: 'bold' }}>
      Aufsichtsrat
      </p>
      <ul style={{maxWidth: '900px',  fontSize: '18px', marginLeft: 'auto', marginRight: 'auto', fontFamily: 'Axel, ITC Officina Display, sans-serif'  }} >
        <li>{supervisoryBoardProps.chairman.name} ({supervisoryBoardProps.chairman.role})</li>
        <li>{supervisoryBoardProps.viceChairman.name} ({supervisoryBoardProps.viceChairman.role})</li>
        <li>
          {supervisoryBoardProps.members.map((member, index) => (
            <li key={index}>{member.name} ({member.role})</li>
          ))}
        </li>
      </ul>

      <br/>

      <p style={{ marginBottom: '18px', fontWeight: 'bold' }}>
        Gründung
      </p>
      <p>
      Die Bürgerenergiegenossenschaft Altusried e.G. (BEGA) sucht Hausbesitzer, die sich an das geplante Nahwärmenetz anschließen möchten.
       Diese Aufnahmen sind entscheidend für die Kalkulation des Netzes und den Wärmepreis. Je mehr Nutzer sich anschließen, desto wirtschaftlicher wird das Projekt.
       </p>

       <p style={{ marginBottom: '18px', fontWeight: 'bold' }}>
        Bauabschnitte
      </p>
       <p>
       Im ersten Bauabschnitt sind folgende Straßen vorgesehen: Poststraße, Hauptstraße ab Rößle bis Netto, Kirchstraße, Schmiedstraße, Illerstraße, 
       Im schönen Grund, Bergstraße, Teile der Kaldenerstraße, evtl. auch Schulstraße und untere Schulstraße.
       </p>
       <p>
        Im zweiten Bauabschnitt: Am Rain, Keltenstraße, Siedlerweg, Drechslerweg, Lusignanstraße, Sattlerweg, Scheibenstraße, Widumstraße, Weststraße, Koppachweg.
        </p>

        <br/>
        <TimeTable/>
        <br/>

        <p> 
       Die Baumaßnahmen umfassen den Bau des Heizhauses, das Verlegen der Hauptleitungen und den Hausanschluss mit Übergabestation. Hausbesitzer, die gerade eine neue Heizung installiert haben, sollten dennoch den Hausanschluss vorsehen. Voraussetzung für die Nutzung des Nahwärmenetzes ist der Beitritt in die Genossenschaft.
       Interessenten können den Erhebungsbogen {' '}
        <Link to="/dokumente" style={{ color: '#007bff', textDecoration: 'underline' }}>
          hier
        </Link>{' '} herunterladen, ausfüllen und an den Vorstand Hugo Wirthensohn (hugo.wirthensohn(at)gmx.de) senden. Nach der Aufnahme und erfolgreichen Kalkulation werden alle Beteiligten über den Wärmepreis informiert und ein verbindlicher Vorvertrag abgeschlossen.
       Für Fragen stehen der Vorstand Hugo Wirthensohn und die Gemeinde zur Verfügung.
       </p>
       <Footer />
    </div>
  );
};

export default Genossenschaft;
