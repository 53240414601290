// src/Impressum.tsx
import React, { useEffect } from 'react';
import Footer from '../components/Footer';

const Impressum: React.FC = () => {

    useEffect(() => {
      // Scroll to the top of the page when the component mounts
      window.scrollTo(0, 0);
    }, []); // Empty dependency array ensures this effect runs only once on mount

    return (
      <div style={{maxWidth: '900px',  fontSize: '18px', marginLeft: 'auto', marginRight: 'auto', padding: '18px', fontFamily: 'Axel, ITC Officina Display, sans-serif',  paddingBottom: '60px'  }}>
        <p style={{ marginBottom: '18px', fontWeight: 'bold' }}>
        Angaben gemäß § 5 TMG
        </p>
        <p>Bürgerenergie Altusried eG</p>
        <p>Rathausplatz 1</p>
        <p>87452 Altusried</p>

        <p style={{ marginBottom: '18px', fontWeight: 'bold' }}>
        Vertreten durch
        </p>
        <p>Hugo Wirthensohn (Vorsitzender), Heribert Hartmann (Stellvertreter)</p>

        <p style={{ marginBottom: '18px', fontWeight: 'bold' }}>
          Vorsitzender des Aufsichtsrats
        </p>
        <p>1. Bürgermeister Max Boneberger </p>

        <p style={{ marginBottom: '18px', fontWeight: 'bold' }}>
          Kontakt
        </p>
        <p>E-Mail: hugo.wirthensohn@gmx.de        </p>

        <p style={{ marginBottom: '18px', fontWeight: 'bold' }}>
          Redaktionell Verantwortlicher
        </p>
        <p>Hugo Wirthensohn </p>

        <p style={{ marginBottom: '18px', fontWeight: 'bold' }}>
          Verbraucherstreitbeilegung/Universalschlichtungsstelle
        </p>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>

        <p style={{ marginBottom: '18px', fontWeight: 'bold' }}>
          Haftung für Inhalte
        </p>
        <p>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
          verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
          gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
          hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
          bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
          Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
          entfernen.
        </p>

        <p style={{ marginBottom: '18px', fontWeight: 'bold' }}>
          Haftung für Links
        </p>
        <p>
          Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können
          wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
          jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
          auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
          permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
          nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        </p>

        <p style={{ marginBottom: '18px', fontWeight: 'bold' }}>
          Urheberrecht
        </p>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht.
          Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
          bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind
          nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber
          erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
          gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </p>

        <p style={{ marginBottom: '18px', fontWeight: 'bold' }}>
          Quelle
        </p>
        <p>
          eRecht24
        </p>
      <Footer/>
      </div>
    );
};

export default Impressum;


