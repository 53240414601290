// src/Routes.tsx

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dokumente from './pages/Dokumente';
import Impressum from './pages/Impressum';
import Kontakt from './pages/Kontakt';
import Home from './pages/Home';
import FAQ from './pages/FAQ';
import Infos from './pages/Infos';
import Projekt from './pages/Projekt';
import Genossenschaft from './pages/Genossenschaft';
import Mitgliedsantrag from './pages/Mitgliedsantrag';
import ResponsiveDrawer from './components/ResponsiveDrawer';

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ResponsiveDrawer />}>
          <Route index element={<Home />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/mitgliedsantrag" element={<Mitgliedsantrag />} />
          <Route path="/genossenschaft" element={<Genossenschaft />} />
          <Route path="/projekt" element={<Projekt />} />
          <Route path="/infos" element={<Infos />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/dokumente" element={<Dokumente />} />
          <Route path="/kontakt" element={<Kontakt />} />
          {/* Add more routes for other pages if needed */}
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRoutes;
