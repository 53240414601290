import React from 'react';
import './StyledList.css';

const StyledList = () => {
  return (
    <div className="container">
      <h2 className="title">Vorteile für Mitglieder</h2>
      <ul className="list">
        <li><strong>Zuverlässige Versorgung und hoher Komfort</strong>: Erleben Sie konstante Leistung und hohen Komfort in Ihrem Zuhause.</li>
        <li><strong>Aktiver Beitrag zu sauberer Luft</strong>: Tragen Sie aktiv zur Verbesserung der Luftqualität bei mit umweltfreundlichen Lösungen.</li>
        <li><strong>Stärkung regionaler Lieferketten</strong>: Unsere Mitgliedschaft fördert die nachhaltige Entwicklung lokaler Versorgungsnetzwerke, die stets zuverlässig Wärme liefern. Dadurch bleibt die Wertschöpfung in der Region und unterstützt die lokale Wirtschaft.</li>
        <li><strong>Platzsparend</strong>: Nutzen Sie den Platz in Ihrem Zuhause effizient, ohne großen Heizöllagerbedarf.</li>
        <li><strong>Keine neuen Heizsysteme erforderlich</strong>: Vermeiden Sie zukünftige Investitionen und Ersatzanschaffungen mit unseren langlebigen Lösungen.</li>
        <li><strong>Kostenbewusste Heizung</strong>: Profitieren Sie von stabilen Heizkosten ohne CO₂-Bepreisung oder plötzliche Preissteigerungen wie bei fossilen Brennstoffen.</li>
        <li><strong>Niedrige Wartungskosten</strong>: Senken Sie Ihre Instandhaltungskosten, da keine Schornsteinfeger oder Emissionsmessungen erforderlich sind.</li>
        <li><strong>Langfristige Steigerung des Immobilienwertes</strong>: Erhöhen Sie den langfristigen Wert Ihrer Immobilie durch unsere energieeffizienten Systeme.</li>
      </ul>
    </div>
  );
};

export default StyledList;
