import React, { useState } from 'react';
import { Paper, Typography, Link, IconButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

interface BlogEntryProps {
  title: string;
  date: string;
  text: string;
  pdfUrl?: string;
  imageUrls?: string[];  // Array of images
}

const BlogEntry: React.FC<BlogEntryProps> = ({ title, date, text, pdfUrl, imageUrls = [] }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openPdf = (event: React.MouseEvent) => {
    event.preventDefault();
    if (pdfUrl) {
      window.open(pdfUrl, '_blank');
    }
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => 
      prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1
    );
  };

  return (
    <Paper 
      elevation={3} 
      style={{ 
        fontSize: '18px', 
        margin: '20px auto', 
        padding: '40px', 
        fontFamily: 'Axel, ITC Officina Display, sans-serif', 
        maxWidth: '900px', 
        background: 'linear-gradient(135deg, #ffffff, #f9f5e2)', 
        borderRadius: '15px',
        boxShadow: '0 12px 24px rgba(0, 0, 0, 0.1)',
        color: '#333',
        position: 'relative'
      }}
    >
      {/* Optional Image Carousel */}
      {imageUrls.length > 0 && (
        <div style={{ position: 'relative', textAlign: 'center', marginBottom: '30px' }}>
          <img 
            src={imageUrls[currentImageIndex]} 
            alt={`Slide ${currentImageIndex + 1}`} 
            style={{ 
              maxWidth: '80%',  // Adjusted to make images smaller
              height: 'auto', 
              borderRadius: '8px', 
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
              transition: 'transform 0.3s',
            }} 
          />
          
          {/* Left Arrow */}
          <IconButton 
            onClick={handlePrevImage} 
            aria-label="Previous Image" 
            style={{ 
              position: 'absolute', 
              top: '50%', 
              left: '10px', 
              transform: 'translateY(-50%)', 
              color: '#9ccc52', // Light green for theme consistency
              backgroundColor: 'rgba(51, 51, 51, 0.7)', // Semi-transparent dark background
              padding: '10px 15px', // Adjust padding for a rectangular shape
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              transition: 'background-color 0.3s',
              border: 'none',
              borderRadius: '5px' // Slightly rounded corners
            }}
            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#333'} // Darken on hover
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'rgba(51, 51, 51, 0.7)'}
          >
            <ArrowBackIos fontSize="large" />
          </IconButton>

          {/* Right Arrow */}
          <IconButton 
            onClick={handleNextImage} 
            aria-label="Next Image" 
            style={{ 
              position: 'absolute', 
              top: '50%', 
              right: '10px', 
              transform: 'translateY(-50%)', 
              color: '#9ccc52', // Light green for theme consistency
              backgroundColor: 'rgba(51, 51, 51, 0.7)', // Semi-transparent dark background
              padding: '10px 15px', // Adjust padding for a rectangular shape
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              transition: 'background-color 0.3s',
              border: 'none',
              borderRadius: '5px' // Slightly rounded corners
            }}
            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#333'} // Darken on hover
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'rgba(51, 51, 51, 0.7)'}
          >
            <ArrowForwardIos fontSize="large" />
          </IconButton>
        </div>
      )}

      <Typography 
        variant="h6" 
        gutterBottom 
        style={{ 
          color: '#9ccc52', 
          fontSize: '25px', 
          fontWeight: '700', 
          textAlign: 'center', 
          letterSpacing: '1px', 
          textTransform: 'uppercase',
          position: 'relative',
          marginBottom: '30px'
        }}
      >
        {title}
        <span style={{
          display: 'block',
          width: '80px',
          height: '6px',
          background: '#f5b830',
          position: 'absolute',
          left: '50%',
          bottom: '-12px',
          transform: 'translateX(-50%)',
          borderRadius: '3px'
        }}></span>
      </Typography>

      <Typography variant="body2" color="textSecondary" gutterBottom style={{ marginBottom: '20px' }}>
        Datum: {date}
      </Typography>

      <Typography variant="body1" paragraph>
        {text}
      </Typography>

      {pdfUrl && (
        <Link 
          href={pdfUrl} 
          target="_blank" 
          rel="noopener noreferrer" 
          onClick={openPdf} 
          style={{ 
            textDecoration: 'none', 
            color: '#9ccd4f',
            display: 'inline-block',
            marginTop: '20px',
            fontSize: '18px'
          }}
        >
          PDF herunterladen
        </Link>
      )}
    </Paper>
  );
};

export default BlogEntry;
