// src/About.tsx

import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import BlogList from '../components/BlogList';


const Infos: React.FC = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div style={{ maxWidth: '800px', fontSize: '18px', marginLeft: 'auto', marginRight: 'auto', padding: '18px', fontFamily: 'Axel, ITC Officina Display, sans-serif',  paddingBottom: '60px'  }}>
     < BlogList />
     <br />
     <Footer/>
    </div>
  );
};

export default Infos;
