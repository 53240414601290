import React from 'react';

interface TimetableItem {
  year: number;
  texts: string[];
}

const timetableData: TimetableItem[] = [
  { year: 2024, texts: ['BA 1.1 Poststraße-Hauptstraße', 'Baubeginn Heizzentrale', 'BA 1.2 Kirchstraße (geplant)'] },
  { year: 2025, texts: ['BA 2.1 Im schönen Grund', 'BA 2.2 Rathausstraße - Zugspitzstraße', 'BA 2.3 Schmiedstraße - Illerstraße', 'BA 2.4 Kaldenerstraße', 'Beginn Wärmelieferung Herbst 2025'] },
  { year: 2026, texts: ['BA 3.1 Poststraße - Am Rain-Lusignanstraße', 'BA 3.2 Poststraße - Keltenstraße - Siedlerweg'] },
  { year: 2027, texts: ['BA 4.1 Andreas-Hofer-Straße (in Vorbereitung)', 'BA 4.2 Schulstraße, Untere Schulstraße (in Vorbereitung)'] }
];

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'stretch' as const,
    padding: '20px',
    fontFamily: 'Axel, ITC Officina Display, sans-serif',
    background: 'linear-gradient(135deg, #ffffff, #f9f5e2)',
    borderRadius: '15px',
    boxShadow: '0 12px 24px rgba(0, 0, 0, 0.1)',
    maxWidth: '900px',
    width: '100%',
    margin: '0 auto', // Center the container
  },
  row: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'flex-start' as const,
    padding: '15px 20px',
    borderRadius: '8px',
    marginBottom: '10px', // Space between items
    transition: 'background 0.3s ease', // Smooth background transition
    width: '100%', // Ensure the row takes full width
  },
  year: {
    width: '100px',
    fontWeight: 'bold' as const,
    color: '#9ccd4f', // Green color for year
    fontSize: '20px',
  },
  textContainer: {
    marginLeft: '18px',
    flexGrow: 1, // Allow text container to grow
  },
  text: {
    display: 'block',
    fontSize: '16px',
    color: '#333', // Dark color for better contrast
  },
  alternating: {
    background: '#f9f5e2', // Light background for even years
  },
  regular: {
    background: '#ffffff', // White background for odd years
  }
};

const TimeTable: React.FC = () => {
  return (
    <div style={styles.container}>
      {timetableData.map((item, index) => (
        <div key={index} style={{ ...styles.row, ...(index % 2 === 0 ? styles.alternating : styles.regular) }}>
          <div style={styles.year}>{item.year}</div>
          <div style={styles.textContainer}>
            {item.texts.map((text, idx) => (
              <span key={idx} style={styles.text}>{text}</span>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TimeTable;
