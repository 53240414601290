// src/pages/FAQ.tsx

import React, { useState, useEffect } from 'react';
import './FAQ.css';
import Footer from '../components/Footer';

const FAQ: React.FC = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const faqData = [
    {
      question: 'Was passiert mit meiner Heizung?',
      answer:
        'Grundsätzlich muss beachtet werden, dass sich eine Heizungsanlage aus mehreren Komponenten zusammensetzt. Im Kessel wird Wasser erwärmt, das mithilfe von Heizungspumpen in den Heizkreislauf des Hauses (für Heizkörper o. Fußbodenheizung) und in einen Warmwasserspeicher zur Brauchwasserbereitung gepumpt wird. Oft ist es auch der Fall, dass der Kessel einen Pufferspeicher mit Heizungswasser erwärmt, der die Heizkreise des Hauses und auch des Warmwasserspeicher speist. Beim Anschluss der Nahwärme wird der Kessel (Öl, Gas, Holz, etc.) gegen einen Nahwärmepufferspeicher ersetzt (s. Abbildungen).',
    },
    {
      question: 'Muss meine alte Heizung ausgebaut werden?',
      answer:
        'Nein, sie muss lediglich stillgelegt werden. Es wird jedoch empfohlen, diese im Zuge des Anschlusses an die Nahwärme auszubauen.',
    },
    {
      question: 'Gibt es einen Unterschied beim Einsatz von Nahwärmepufferspeichern und Übergabestationen?',
      answer:
        'Durch den Einsatz von Nahwärmepufferspeichern wird die Energieeffizienz des Nahwärmenetzes stark verbessert, es fallen deutlich weniger Verluste an, und das Nahwärmenetz kann in den Rohrleitungsquerschnitten kleiner dimensioniert werden. Dadurch können in jedem der oben aufgeführten Punkte Kosten eingespart werden.',
    },
    {
      question: ' Wann kommt ein Nahwärmepufferspeicher zum Einsatz und wann eine Übergabestation? ',
      answer:
        'In der Regel bekommen Sie einen Nahwärmepufferspeicher, in welchem ein Glattrohrwärmetauscher verbaut ist. Eine Übergabestation kommt zum Einsatz, wenn ein Bestandspufferspeicher integriert wird.  Welche Übergabeeinheit für Ihr Haus die richtige ist, wird vom Heizungsbauer, der für das Nahwärmenetz  beauftragt wird, ermittelt. ',
    },
    {
      question: 'Ich habe bereits vor der Nahwärme einen Pufferspeicher, kann ich diesen behalten?  ',
      answer:
        'Ja, ein Bestandpufferspeicher (nicht zu verwechseln mit dem Warmwasserspeicher, der ausschließlich für  die Brauchwasserbereitung zuständig ist)  in gutem Zustand, kann ohne weiteres in das Nahwärmesystem  eingebunden werden (s. Frage Nr. 6 Beispiel 2). Sollte dieser jedoch schon sehr alt sein, erkundigen Sie   sich bitte beim Heizungsbauer, ob ein weiterer Einsatz sinnvoll ist oder besser mit ausgetauscht werden   sollte.  ',
    },
    {
      question: 'Welche Komponenten werden durch den Anschluss an die Nahwärme in meinem Keller installiert?  ',
      answer:
        'Ja, ein Bestandpufferspeicher (nicht zu verwechseln mit dem Warmwasserspeicher, der ausschließlich für  die Brauchwasserbereitung zuständig ist)  in gutem Zustand, kann ohne weiteres in das Nahwärmesystem  eingebunden werden (s. Frage Nr. 6 Beispiel 2). Sollte dieser jedoch schon sehr alt sein, erkundigen Sie   sich bitte beim Heizungsbauer, ob ein weiterer Einsatz sinnvoll ist oder besser mit ausgetauscht werden   sollte.  ',
    },
    {
      question: ' Können meine Heizkörper, meine Fußbodenheizung und die dazugehörige Verrohrung bestehen bleiben?   ',
      answer:
        'Ja, es ändert sich lediglich die Wärmequelle. (s. Frage Nr. 6)  ',
    },
    {
      question: ' Kann meine Brauchwasserbereitung bestehen bleiben?   ',
      answer:
        'Ja, wenn sich der bestehende Brauchwasserspeicher oder die Frischwasserstation in gutem Zustand  befinden.  Nein, wenn der Brauchwasserspeicher in den Kessel integriert ist oder die Warmwasserbereitung aus  Altersgründen getauscht werden muss. Falls dies der Fall ist muss die Trinkwasserbereitung ebenfalls mit   getauscht werden. Als Alternative bieten sich eine Hygienewendel im Nahwärmepufferspeicher, eine   Frischwasserstation oder ein Boiler. Für genauere Informationen wenden Sie sich bitte an den für das Nahwärmenetz zuständigen Heizungsbauer.   ',
    },
    {
      question: ' Kann ich meine Pumpen behalten?   ',
      answer:
        'Ja. Falls es sich jedoch um alte Pumpen handelt, sollten Sie auch ohne Anschluss an die Nahwäme einen  Austausch in Betracht ziehen. Energieeffiziente Pumpen amortisieren sich bereits nach ein – zwei Jahren,  alleine durch die Einsparung der Stromkosten.   ',
    },
    {
      question: 'Kann meine Brauchwasserbereitung bestehen bleiben?',
      answer: 'Ja, wenn sich der bestehende Brauchwasserspeicher oder die Frischwasserstation in gutem Zustand befinden. Nein, wenn der Brauchwasserspeicher in den Kessel integriert ist oder die Warmwasserbereitung aus Altersgründen getauscht werden muss. Falls dies der Fall ist muss die Trinkwasserbereitung ebenfalls mit getauscht werden. Als Alternative bieten sich eine Hygienewendel im Nahwärmepufferspeicher, eine Frischwasserstation oder ein Boiler an. Für genauere Informationen wenden Sie sich bitte an den für das Nahwärmenetz zuständigen Heizungsbauer.'
    },
    {
      question: 'Ich habe eine Solaranlage, kann diese bestehen bleiben und in das Nahwärmesystem eingebunden werden?',
      answer: 'Ja, eine Solaranlage kann bestehen bleiben und auch mit einem Nahwärmeanschluss ins Heizsystem integriert werden. In diesem Punkt muss unterschieden werden, handelt es sich um eine trinkwasserunterstützende Solarthermie (in Form eines Wendels innerhalb des Warmwasserspeichers), kann diese ohne Umbaumaßnahmen bestehen bleiben. Bei einer heizungsunterstützenden Solarthermie wird ein Bestandspufferspeicher über eine Wendel beheizt. In diesem Fall kann ebenfalls alles ohne Umbaumaßnahmen bestehen bleiben und wie im Beispiel mit einem Bestandspuffer weiter verfahren werden. (s. Frage Nr. 6)'
    },
    {
      question: 'Wie lange dauert der Umbau zum Anschluss der Nahwärme?',
      answer: 'Der zeitliche Aufwand ist stark davon abhängig, wie aufwendig und effizient der jeweilige Umbau sein soll. In der Regel (!) beträgt die Zeitspanne einen Tag. Für eine genauere Einschätzung wenden Sie sich bitte an Ihren oder den für die Nahwärme zuständigen Heizungsbauer.'
    },
    {
      question: 'Was ist, wenn der Betreiber die versprochene Wärme nicht liefern kann?',
      answer: 'In Ihrem Nahwärmenetz besteht eine vertraglich gesicherte Wärmegarantie. D.h. der Nahwärmenetzbetreiber muss zu jeder Zeit Wärme liefern. Wie er diese Wärmegarantie absichert obliegt seiner Verantwortung.'
    },
    {
      question: 'Ich habe einen Kachelofen, kann ich diesen weiterhin befeuern?',
      answer: 'Hier muss unterschieden werden, ob der Kachelofen zur gemütlichen Atmosphäre oder für die zentrale Beheizung des Hauses genutzt wird. Wird der Kachelofen zur gemütlichen Atmosphäre genutzt hat dieser keinerlei Auswirkungen auf den Nahwärmeanschluss und kann wie gewohnt weiter betrieben werden. Dient der Kachelofen allerdings als Zentralheizung, wird dieser durch den Nahwärmeanschluss ersetzt. Wir bitten aber darum den Holzverbrauch in jedem Fall auf dem Erhebungsbogen mit anzugeben.'
    },
    {
      question: 'Wo befinden sich die Haftungsgrenzen? Wo befinden sich die Eigentumsgrenzen?',
      answer: 'Die Haftungsgrenzen sind identisch mit den Eigentumsgrenzen und werden in den Vertragsbedingungen der Nahwärmegesellschaft bzw. des Netzbetreibers geregelt. Der jeweilige Eigentümer haftet auch im Reparaturfall für sein Eigentum. Die zwei folgenden Konstellationen sind möglich: die Wärmeübertragung mittels Hochleistungsspeichers (links) und die Wärmeübertragung mittels Übergabestation (rechts – bsp. wenn ein Bestandspuffer mit eingebunden werden soll):'
    },
    {
      question: 'Wird von Seiten der Nahwärme der komplette Umbau meiner Heizanlage gemacht?',
      answer: 'Nein, sowohl der Anschlussnehmer, als auch der Nahwärmenetzbetreiber sind für die Umbaumaßnahmen ihres Eigentums verantwortlich – siehe Eigentumsgrenzen Abb. 9 und Abb. 10. Der Netzbetreiber ist dafür verantwortlich die Wärmeleitung ins Haus zu legen und diese mit der entsprechenden Übergabetechnik (Hochleistungsspeicher oder Übergabestation) im Heizraum zu verbinden. Das Verrohren der hausinternen Heizkreise und auch der Trinkwasserbereitung mit der Übergabetechnik liegt im Verantwortungsbereich des Hausbesitzers.'
    },
    {
      question: ' Wird die Kernlochbohrung in der Hauswand auch wieder dicht?',
      answer: 'Wird die Hauseinführung fachgerecht durchgeführt, wird diese im Regelfall auch wieder dicht. Die Verantwortung liegt hier beim auszuführenden Unternehmen.'
    },
    // ... (add more FAQ items as needed)
  ];

  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div style={{maxWidth: '700px',  fontSize: '18px', marginLeft: 'auto', marginRight: 'auto', padding: '18px', fontFamily: 'Axel, ITC Officina Display, sans-serif',  paddingBottom: '60px'  }}>
      {faqData.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => handleToggle(index)}>
            <span>{faq.question}</span>
            <span className={`arrow ${expandedIndex === index ? 'expanded' : ''}`}>&#9654;</span>
          </div>
          {expandedIndex === index && <p className="faq-answer">{faq.answer}</p>}
        </div>
      ))}
      <Footer />
    </div>
  );
};

export default FAQ;
