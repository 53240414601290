import React, { useEffect } from 'react';
import Footer from '../components/Footer';

const Projekt: React.FC = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div
      style={{
        maxWidth: '800px',
        fontSize: '18px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '18px',
        paddingBottom: '60px',
        fontFamily: 'Axel, ITC Officina Display, sans-serif',
      }}
    >
      <p>
        Im Zentrum von Altusried werden derzeit das neues Rathaus und die Seniorenresidenz
        errichtet. Es bestehen dort auch einige große, denkmalgeschützte
        Gebäude, wie z.B. die Gasthöfe „Zum Rößle“ und „Bären“ sowie auch alte Wohn- und
        Geschäftshäuser. Außerdem haben die regionale Raiffeisenbank und die katholische Kirche
        ihre Verwaltungsgebäude dort errichtet. Mit den zuvor beschriebenen Baumaßnahmen,
        genannt „Neue Mitte“, sollen im Ortszentrum (Marktplatz) neben der Sanierung des alten
        Rathauses zusätzlich die Außenanlagen neugestaltet werden. In der Ortsmitte wurden
        energetische Ertüchtigungsmaßnahmen bisher nur in einzelnen Gebäuden umgesetzt. Die
        meisten Gebäude werden über Erdgas- und Heizölversorgungen beheizt.
      </p>

      <div style={{ width: '100%', margin: '18px auto', textAlign: 'center' }}>
        <img
          src={`${process.env.PUBLIC_URL}/Heizhaus1.JPG`}
          alt="Animation des Heizhauses"
          style={{
            width: '100%',
            height: 'auto',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', // Add shadow for a lifted effect
          }}
        />
        <p
          style={{
            color:  '#9ccd4f',
            fontFamily: 'Axel, ITC Officina Display, sans-serif',
            marginTop: '10px',
            fontSize: '16px',
          }}
        >
          Animation des Heizhauses
        </p>
      </div>

      <p>
        Während der Ausführung der vorstehend genannten Neubauten im Ortszentrum wurde
        festgestellt, dass ein neuer Ableitungskanal für das auftretende Schichtenwasser erforderlich
        ist. Die Bauarbeiten für diesen Kanal werden gerade durchgeführt und Ende 2024 fertiggestellt. Dieser verläuft entlang der Hauptstraße und der
        Poststraße und wird dann in den südlich gelegenen Koppach eingeleitet. Die
        Baumaßnahme erfordert im Bereich der Rohrgräben einen entsprechenden Straßenaufbruch.
        Hierdurch ist ein Synergieeffekt durch eine Mitverlegung der Fernwärmeleitungen möglich.
      </p>

      <div style={{ width: '100%', margin: '18px auto', textAlign: 'center' }}>
        <img
          src={`${process.env.PUBLIC_URL}/Heizhaus2.JPG`}
          alt="Animation des Heizhauses"
          style={{
            width: '100%',
            height: 'auto',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', // Add shadow for a lifted effect
          }}
        />
        <p
          style={{
            color: '#9ccd4f',
            fontFamily: 'Axel, ITC Officina Display, sans-serif',
            marginTop: '10px',
            fontSize: '16px',
          }}
        >
          Animation des Heizhauses
        </p>
      </div>

      <p>
        Südlich der „Neuen Mitte“ befindet sich das Schulzentrum von Altusried bestehend aus Grund und
        Mittelschule, Turnhalle, Hallenbad und einem Veranstaltungsgebäude (Theaterkästle).
        Auch das Haus der Vereine (ehem. Kindergarten St. Blasius), der neu erstellte 7-gruppige
        Kindergarten St. Blasius und das Trachtenheim befinden sich in der Nähe des
        Schulkomplexes. Die Gebäude sind bereits durch ein kleines Nahwärmenetz miteinander verbunden.
        Die Heizzentrale besteht aus zwei Niedertemperaturheizkessel mit jeweils einem Gas- und
        einem Heizölanschluss. Außerdem versorgt ein mobiles BHKW während der Heizperiode den
        Schulkomplex mit Wärme und Strom. Das Nahwärmeversorgungssystem wurde 2019
        erneuert. Das bestehende Nahwärmenetz wird in einem Übergabebauwerk in das gesamte Fernwärmenetz mit eingebunden.
      </p>

      <p>
        Südlich des Bauhofes in der Poststraße besitzt der Markt Altusried ein großes unbebautes
        Grundstück, welches für zukünftige Erweiterungen des Kindergartens im nördlichen Teil
        vorgehalten wird. Angrenzend an das Grundstück befindet sich, im südwestlichen Bereich des Ortes, ein
        größeres Wohngebiet aus den 70er Jahren. Dieses wird als Wohngebiet „Am Rain“
        bezeichnet. Bisher wurden hier sehr wenige energetische Sanierungsmaßnahmen durchgeführt.
        Die Kesselanlagen der meisten Gebäude sind nicht mehr auf dem Stand der Technik und
        müssen zeitnah getauscht werden. Der im südlichen Bereich liegende Teil befindet sich im
        erweiterten Wasserschutzgebiet, weshalb die mit Heizöllagern ausgestatteten Anwesen in
        absehbarer Zeit auf eine andere Energieform umsteigen, oder aufwendige
        Sicherungsmaßnahmen ihrer Lagerstätten vornehmen müssen.
      </p>

      <p>
        Durch die umfangreichen
        Baumaßnahmen in der Ortsmitte (neues Rathaus und Seniorenwohnanlage), die bestehenden
        Kesselanlagen im bisherigen Rathaus, im Schulzentrum sowie der zahlreichen
        Heizkesselerneuerungen im Wohngebiet „Am Rain“ ist die Diskussion zur Konzeptionierung
        einer zentralen Wärmeversorgungsanlage entstanden. Die Initiatoren haben die Idee am 28.
        Oktober 2021 im Bauausschuss vorgestellt und der Marktrat Altusried hat sich am 16.
        Dezember 2021 mit einstimmiger Mehrheit für die Entwicklung eines Nahwärmekonzeptes
        ausgesprochen.
      </p>

      <Footer />
    </div>
  );
};

export default Projekt;
