import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import RoundImage from '../components/RoundImage';

const Kontakt: React.FC = () => {
  
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div style={{ maxWidth: '800px', fontSize: '18px', marginLeft: 'auto', marginRight: 'auto', padding: '18px', fontFamily: 'Axel, ITC Officina Display, sans-serif', paddingBottom: '60px' }}>
      <RoundImage   src={`${process.env.PUBLIC_URL}/Hugo.jpg`} alt="Hugo Wirthensohn" />
      <br/>
      <p style={{ marginBottom: '18px' }}>
        <strong>Name:</strong> Hugo Wirthensohn
      </p>
      <p style={{ marginBottom: '18px' }}>
        <strong>Addresse:</strong> Gemeinde Altusried, Rathausplatz 1, 87452 Altusried
      </p>
      <p style={{ marginBottom: '18px' }}>
        <strong>Email:</strong> hugo.wirthensohn(at)gmx.de
      </p>
      <p style={{ marginBottom: '18px' }}>
        <strong>Telefonnummer:</strong> +49 171 6533568
      </p>
      <Footer />
    </div>
  );
}


export default Kontakt;
