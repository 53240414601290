import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import CenteredImage from '../components/CenteredImage';

// Container for the main content
const ContentWrapper = styled('div')({
  position: 'relative',
  zIndex: 1,
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#333',
  fontFamily: 'Axel, ITC Officina Display, sans-serif',
  paddingBottom: '60px',
  paddingLeft: '280px', // Adjust for the width of the left drawer
  '@media (max-width: 600px)': {
    paddingLeft: '0', // Remove padding on mobile
  },
});

// Background image carousel container
const CarouselBackground = styled('div')({
  position: 'fixed',
  top: 0,
  left: 280,
  width: 'calc(100% - 280px)',
  height: '100%',
  overflow: 'hidden',
  zIndex: -1,
  '@media (max-width: 600px)': {
    left: 0, // Adjust for mobile
    width: '100%', // Full width on mobile
  },
});

const CarouselImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
  transition: 'opacity 1.5s ease-in-out',
  opacity: 0,
});

// Adjusted LogoWrapper to be fixed in the bottom left corner
const LogoWrapper = styled('div')({
  position: 'fixed', // Fixed position
  bottom: '0px', // Distance from the bottom
  right: '0px', // Distance from the left
  zIndex: 2, // Ensure the logo is above the carousel
  padding: '0px', // Add some padding around the logo
  '@media (min-width: 600px)': {
    left: '300px', // Adjust for drawer width when the screen is wider
  },
});

const Home: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const images = [
    `${process.env.PUBLIC_URL}/Altusried-003.jpg`,
    `${process.env.PUBLIC_URL}/spatenstich/img2.jpg`,
    `${process.env.PUBLIC_URL}/spatenstich/img3.jpg`,
    `${process.env.PUBLIC_URL}/altusried-luftbild.jpg`,
    `${process.env.PUBLIC_URL}/spatenstich/img1.jpg`,
    `${process.env.PUBLIC_URL}/spatenstich/IMG-20240913-WA0017.jpg`,
  ];

  useEffect(() => {
    const preloadImages = async () => {
      await Promise.all(images.map((src) => new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
      })));
      setImagesLoaded(true);
    };

    preloadImages();
  }, [images]);

  useEffect(() => {
    const interval = setInterval(() => {
      setPreviousIndex(currentIndex);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  return (
    <ContentWrapper>
      <CarouselBackground>
        {imagesLoaded && images.map((image, index) => (
          <CarouselImage
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
            style={{
              opacity: currentIndex === index || previousIndex === index ? 1 : 0,
              zIndex: currentIndex === index ? 2 : 1,
            }}
          />
        ))}
      </CarouselBackground>

      {/* Logo in the bottom left corner */}
      <LogoWrapper>
        <img src={`${process.env.PUBLIC_URL}/bundesministerium_logo.png`} alt="Bundesministerium Logo" style={{ maxWidth: '20%', height: 'auto' }} />
      </LogoWrapper>
    </ContentWrapper>
  );
};

export default Home;
