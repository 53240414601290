import React from 'react';

// Define the props type
interface RoundImageProps {
  src: string;
  alt: string;
}

const RoundImage: React.FC<RoundImageProps> = ({ src, alt }) => {
  // Define inline styles for the image
  const imageStyles: React.CSSProperties = {
    width: '200px',           // Set explicit width
    height: '200px',          // Set explicit height to ensure it's a square
    borderRadius: '50%',      // This makes the image round
    objectFit: 'cover',       // Ensures the image fits within the circle without distortion
    marginLeft: 'auto',       // Center the image horizontally
    marginRight: 'auto',      // Center the image horizontally
    display: 'block',         // Ensures the image takes up only its content width
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', // Add shadow for a lifted effect

  };

  return (
    <img
      src={src}
      alt={alt}
      style={imageStyles}  // Apply the styles inline
    />
  );
};

export default RoundImage;
