import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import WorkIcon from '@mui/icons-material/Work';
import MailIcon from '@mui/icons-material/Mail';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ArticleIcon from '@mui/icons-material/Article';
import InfoIcon from '@mui/icons-material/Info';

const drawerWidth = 280;
const green = '#9ccd4f';

interface Props {
  window?: () => Window;
}

const ResponsiveDrawer = (props: Props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const location = useLocation();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const isSelected = (route: string) => {
    return location.pathname === route;
  };

  const getPageTitle = () => {
    switch (location.pathname) {
      case '/':
        return 'Bürgerenergie Altusried e.G.'
      case '/projekt':
        return 'Unser Projekt';
      case '/genossenschaft':
        return 'Genossenschaft';
      case '/mitgliedsantrag':
        return 'Mitglied werden';
      case '/infos':
        return 'Aktuelle Infos';
      case '/faq':
        return 'FAQ';
      case '/dokumente':
        return 'Dokumente';
      case '/kontakt':
        return 'Kontakt';
      case '/impressum':
        return 'Impressum';
      default:
        return '';
    }
  };

  const drawer = (
    <div style={{ fontFamily: 'Axel, ITC Officina Display, sans-serif' }}>
      <Toolbar>
        <Link to="/">
          <img
            src={process.env.PUBLIC_URL + '/Logo.jpg'}
            alt="Logo"
            style={{ width: '240px', height: 'auto', marginRight: '5px' }}
          />
        </Link>
      </Toolbar>
      <List>
        {[
          { text: 'Genossenschaft', icon: <GroupsIcon />, route: '/genossenschaft' },
          { text: 'Unser Projekt', icon: <WorkIcon />, route: '/projekt' },
          { text: 'Mitglied werden', icon: <PersonIcon />, route: '/mitgliedsantrag' },
          { text: 'Aktuelle Infos', icon: <InfoIcon />, route: '/infos' },
          { text: 'Dokumente', icon: <ArticleIcon />, route: '/dokumente' },
          { text: 'FAQ', icon: <ContactSupportIcon />, route: '/faq' },
          { text: 'Kontakt', icon: <MailIcon />, route: '/kontakt' },
          { text: 'Impressum', icon: <MenuBookIcon />, route: '/impressum' },
        ].map(({ text, icon, route }, index) => (
          <React.Fragment key={text}>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={route}
                selected={isSelected(route)}
                sx={{
                  fontWeight: isSelected(route) ? 'bold' : 'normal',
                  color: isSelected(route) ? green : 'inherit',
                  '&:hover': {
                    color: green,
                  },
                }}
              >
                <ListItemIcon sx={{ color: isSelected(route) ? green : 'inherit' }}>{icon}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
            {index < 6 && <Divider sx={{ borderBottom: 0 }} />} {/* Add Divider except for the last item */}
          </React.Fragment>
        ))}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          bgcolor: green,
          fontFamily: 'Axel, ITC Officina Display, sans-serif',
          boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Toolbar sx={{ justifyContent: 'center' }}> {/* Centering the Toolbar content */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography 
            variant="h6" 
            noWrap 
            sx={{
              fontFamily: 'Axel, ITC Officina Display, sans-serif', 
              fontSize: '1rem', // Smaller font size
              color:  '#333',
              fontWeight: 'bold', // Make the font bold
            }}
          >
            {getPageTitle()}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ fontFamily: 'Axel, ITC Officina Display, sans-serif', width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            fontFamily: 'Axel, ITC Officina Display, sans-serif',
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            fontFamily: 'Axel, ITC Officina Display, sans-serif',
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
      >
        <Toolbar />
        <Outlet /> {/* This will render the routed content */}
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
