import React, { useEffect } from 'react';
import { Container, Box, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from '../components/Footer';

const Dokumente: React.FC = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  // Define URLs for each document
  const documents = [
    { name: 'Erhebungsbogen', url: `${process.env.PUBLIC_URL}/Erhebungsbogen.pdf` },
    { name: 'Beitrittserklärung', url: `${process.env.PUBLIC_URL}/Beitrittserklaerung.pdf` },
    { name: 'Wärmeliefervertrag', url: `${process.env.PUBLIC_URL}/Waermeliefervertrag.pdf` },
    { name: 'Entgelte', url: `${process.env.PUBLIC_URL}/Entgelte.pdf` },
    { name: 'Allgemeine Versorgungsbedingungen', url: `${process.env.PUBLIC_URL}/Versorgungsbedingungen.pdf` },
    { name: 'TAB', url: `${process.env.PUBLIC_URL}/TAB.pdf` },
    { name: 'Bauabschnitt Mitte und West', url: `${process.env.PUBLIC_URL}/Bauabschnitt.pdf` },
    { name: 'Ansicht Heizwerk', url: `${process.env.PUBLIC_URL}/Heizwerk.pdf` },
    { name: 'Satzung', url: `${process.env.PUBLIC_URL}/Satzung.pdf`}
  ];

  const handleDownload = (url: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const theme = createTheme({
    palette: {
      primary: {
        main:  '#9ccd4f',
      },
    },
  });
  

  return (
    <ThemeProvider theme={theme}>
      <div style={{         maxWidth: '800px', fontSize: '18px', marginLeft: 'auto', marginRight: 'auto', padding: '18px', fontFamily: 'Axel, ITC Officina Display, sans-serif',  paddingBottom: '60px', backgroundColor: 'white', color: 'black' }}>
        <Container style={{ fontSize: '18px', padding: '18px' }}>
          <p>
            Hier können Sie verschiedene Dokumente herunterladen. Bitte wählen Sie aus:
          </p>
          <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
            {documents.map((doc, index) => (
              <Box key={index} mb={2} width="100%" display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary" // Use the primary color defined in the theme
                  sx={{
                    width: '100%',  // Ensure buttons take full width of their container
                    maxWidth: '500px', // Set a max width to ensure they are not too wide
                    height: 'auto', // Adjust height as needed
                    backgroundColor:  '#9ccd4f',
                    color: '#333', // Text color
                    '&:hover': {
                      backgroundColor: '#f2d24e', // Hover background color
                    },
                  }}
                  onClick={() => handleDownload(doc.url, `${doc.name}.pdf`)}
                >
                  {doc.name}
                </Button>
              </Box>
            ))}
          </Box>
        </Container>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default Dokumente;
