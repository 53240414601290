// src/Mitgliedsantrag.tsx

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import StylishList from '../components/StylishList';


const Mitgliedsantrag: React.FC = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this effect runs only once on mount
  
  return (
    <div style={{maxWidth: '900px',  fontSize: '18px', marginLeft: 'auto', marginRight: 'auto', padding: '18px', fontFamily: 'Axel, ITC Officina Display, sans-serif',  paddingBottom: '60px'  }}>
      <p>
        Unser Nahwärmeprojekt setzt eine Mitgliedschaft in unserer Genossenschaft voraus. Das gemeinsame Ziel ist, die
        Wärmewende selbst in die Hand zu nehmen, Spekulationen auszuschalten, keine fossilen Energien mehr zu verbrauchen
        und die Wertschöpfung in der Region zu halten.
      </p>
      <p>
        Je mehr Mitglieder wir sind, um so stabiler ist unser Netz und langfristig unser Preis. Der Mitgliedsbeitrag
        liegt bei 5.000,00€ und ist pro Haushalt oder Übergabestation einzulegen. Sollte ein Haus oder eine Wohnung
        verkauft oder vererbt werden, geht der Genossenschaftsanteil an den nächsten Besitzer über und muss an das
        bisherige Mitglied ausbezahlt werden.
      </p>
      <p>
        Die Satzung sieht keine Nachschusspflicht vor. Die Mitgliedschaft wird vom Vorstand genehmigt und fortlaufend in
        das Mitgliederverzeichnis eingetragen. Das Mitgliedsformular ist unter{' '}
        <Link to="/dokumente" style={{ color: '#007bff', textDecoration: 'underline' }}>
          Dokumente
        </Link>{' '}
        bereitgestellt, so wie auch die Satzung.
      </p>
      <StylishList/>
      <Footer/>
    </div>
  );
};

export default Mitgliedsantrag;
